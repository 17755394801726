<template>
  <div v-if="Object.keys(headerData).length" id="user-profile">
    <template>
      <b-card
        class="profile-header mb-2 header-card"
        :img-src="headerData.bannerLarge"
        img-top
        alt="cover photo"
        body-class="p-0"
      >
        <!-- profile picture -->
        <div class="position-relative">
          <div class="profile-img-container d-flex align-items-center">
            <div class="profile-img bg-transparent border-0 shadow-none">
              <b-img
                :src="headerData.avatar"
                rounded="circle"
                fluid
                class="border-2 border-white"
                alt="profile photo"
              />
            </div>
            <!-- profile title -->
            <div class="profile-title">
              <h2 class="text-white">
                {{ headerData.nickname }}
              </h2>
              <p class="text-white">
                {{ headerData.category }}
              </p>
              <div class="d-flex justify-content-between">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="SeeChannel"
                >
                  {{ $t("Pages.City.Button.see_ch") }}
                </b-button>

                <!-- <b-dropdown
                  id="dropdown-grouped"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  right
                  class="dropdown-icon-wrapper mr-2 ml-2"
                >
                  <template #button-content>
                    <span>{{ $t("Pages.City.Button.Report") }}</span>
                  </template>

                  <b-dropdown-item v-for="type in types" :key="type.id">
                    <span @click="submitReport(type.id)">
                      {{ type.title }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown> -->

              </div>
            </div>
          </div>
        </div>

        <!-- profile navbar -->
        <div class="profile-header-nav mt-2">
          <template>
            <b-card v-if="data" no-body class="card-statistics">
              <b-card-header v-if="headerData.createdAt"
                >{{ $t("Pages.City.Title.joined_date") }}
                {{ MyDate.getFormatDate(headerData.createdAt) }}</b-card-header
              >
              <b-card-body class="statistics-body">
                <b-row>
                  <b-col lg="12 mb-3">
                    <p class="pre-formatted">
                      {{ headerData.description }}
                    </p>
                  </b-col>
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_publishedAt.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" :variant="data_publishedAt.color">
                          <feather-icon
                            size="24"
                            :icon="data_publishedAt.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ MyDate.getFormatDate(data_publishedAt.title) }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_publishedAt.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_videos.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" :variant="data_videos.color">
                          <feather-icon size="24" :icon="data_videos.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data_videos.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_videos.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_views.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" :variant="data_views.color">
                          <feather-icon size="24" :icon="data_views.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data_views.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_views.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_subscribers.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" :variant="data_subscribers.color">
                          <feather-icon
                            size="24"
                            :icon="data_subscribers.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data_subscribers.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_subscribers.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_sub30Days.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" :variant="data_sub30Days.color">
                          <feather-icon size="24" :icon="data_sub30Days.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <!-- <h4
                          v-if="data_sub30Days.title > 0"
                          class="text-success font-weight-bolder mb-0"
                        >
                          {{ data_sub30Days.title }}
                          <i class=""
                            ><feather-icon size="24" icon="ArrowUpIcon"
                          /></i>
                        </h4>
                        <h4
                          v-else-if="data_views30Days.title < 0"
                          class="text-danger font-weight-bolder mb-0"
                        >
                          {{ data_sub30Days.title }}
                          <i class=""
                            ><feather-icon size="24" icon="ArrowDownIcon"
                          /></i>
                        </h4> -->
                        <h4  class="font-weight-bolder mb-0">
                          {{ data_sub30Days.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_sub30Days.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_views30Days.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" :variant="data_views30Days.color">
                          <feather-icon
                            size="24"
                            :icon="data_views30Days.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <!-- <h4
                          v-if="data_views30Days.title > 0"
                          class="text-success font-weight-bolder mb-0"
                        >
                          {{ data_views30Days.title }}
                          <i class="pb-1"
                            ><feather-icon size="24" icon="ArrowUpIcon"
                          /></i>
                        </h4>
                        <h4
                          v-else-if="data_views30Days.title < 0"
                          class="text-danger font-weight-bolder mb-0"
                        >
                          {{ data_views30Days.title }}
                          <i class="pb-1"
                            ><feather-icon size="24" icon="ArrowDownIcon"
                          /></i>
                        </h4> -->
                        <h4 class="font-weight-bolder mb-0">
                          {{ data_views30Days.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_views30Days.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  
<!--                   
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_estimateMonthlyRevenue.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar
                          size="48"
                          :variant="data_estimateMonthlyRevenue.color"
                        >
                          <feather-icon
                            size="24"
                            :icon="data_estimateMonthlyRevenue.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data_estimateMonthlyRevenue.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_estimateMonthlyRevenue.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    xl="3"
                    sm="6"
                    :class="data_estimateYearlyRevenue.customClass"
                    class="mb-2 mt-2"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar
                          size="48"
                          :variant="data_estimateYearlyRevenue.color"
                        >
                          <feather-icon
                            size="24"
                            :icon="data_estimateYearlyRevenue.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data_estimateYearlyRevenue.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ data_estimateYearlyRevenue.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col> -->

                </b-row>
              </b-card-body>
            </b-card>
          </template>
        </div>
      </b-card>

      <!--/ profile navbar -->
      <!-- <b-row>
        <b-col lg="6" sm="12">
          <b-overlay
            :show="!chartjsDataSubscribers.lineChart.status"
            spinner-variant="primary"
            variant="transparent"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-card no-body v-if="!chartjsDataSubscribers.lineChart.status">
              <b-card-header>
                <div>
                  <b-card-sub-title>{{
                    $t("Pages.City.Title.Subscriber")
                  }}</b-card-sub-title>
                </div>
              </b-card-header>

              <b-card-body>
                <chartjs-component-line-chart
                  :height="430"
                  :data="chartjsDataSubscribers.lineChart.data"
                  :options="chartjsDataSubscribers.lineChart.options"
                  :plugins="plugins"
                />
              </b-card-body>
            </b-card>
          </b-overlay>
          <b-card no-body v-if="chartjsDataSubscribers.lineChart.status">
            <b-card-header>
              <div>
                <b-card-sub-title>{{
                  $t("Pages.City.Title.Subscriber")
                }}</b-card-sub-title>
              </div>
            </b-card-header>

            <b-card-body>
              <chartjs-component-line-chart
                :height="430"
                :data="chartjsDataSubscribers.lineChart.data"
                :options="chartjsDataSubscribers.lineChart.options"
                :plugins="plugins"
              />
            </b-card-body>
          </b-card>
        </b-col>

        <b-col lg="6" sm="12">
          <b-overlay
            :show="!chartjsDataVideos.lineChart.status"
            spinner-variant="primary"
            variant="transparent"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-card no-body v-if="!chartjsDataVideos.lineChart.status">
              <b-card-header>
                <div>
                  <b-card-sub-title>{{
                    $t("Pages.City.Title.Videos")
                  }}</b-card-sub-title>
                </div>
              </b-card-header>

              <b-card-body>
                <chartjs-component-line-chart
                  :height="430"
                  :data="chartjsDataVideos.lineChart.data"
                  :options="chartjsDataVideos.lineChart.options"
                  :plugins="plugins"
                />
              </b-card-body>
            </b-card>
          </b-overlay>
          <b-card no-body v-if="chartjsDataVideos.lineChart.status">
            <b-card-header>
              <div>
                <b-card-sub-title>{{
                  $t("Pages.City.Title.Videos")
                }}</b-card-sub-title>
              </div>
            </b-card-header>

            <b-card-body>
              <chartjs-component-line-chart
                :height="430"
                :data="chartjsDataVideos.lineChart.data"
                :options="chartjsDataVideos.lineChart.options"
                :plugins="plugins"
              />
            </b-card-body>
          </b-card>
        </b-col>
        
      </b-row> -->


    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCardSubTitle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import MyDate from "@core/components/date/MyDate.vue";
import ChartjsComponentLineChart from "../charts/charts-components/ChartjsComponentLineChart.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import chartjsDataVideos from "./chartjsDataViews";
import chartjsDataSubscribers from "./chartjsDataSubscribers";

export default {
  components: {
    ChartjsComponentLineChart,
    StatisticCardWithAreaChart,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardSubTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      MyDate,
      chartjsDataVideos,
      chartjsDataSubscribers,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
      headerData: {
        avatar: "",
        nickname: "",
        category: "",
        description: "",
        bannerLarge: "",
        created_at: "",
        channelId: "",
      },
      data_publishedAt: {
        icon: "CalendarIcon",
        color: "light-danger",
        title: "",
        subtitle: "",
        customClass: "mb-2 mb-xl-0",
      },
      data_videos: { icon: "VideoIcon" },
      data_subscribers: { icon: "UserIcon" },
      data_views: { icon: "RadioIcon" },
      data_sub30Days: { icon: "UserIcon" },
      // data_estimateMonthlyRevenue: { icon: "CloudIcon" },
      // data_estimateYearlyRevenue: { icon: "CloudIcon" },
      data_views30Days: { icon: "RadioIcon" },
      data: [
        {
          icon: "RadioIcon",
          color: "light-primary",
          title: "230k",
          subtitle: "Sales",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "8.549k",
          subtitle: "Customers",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: "1.423k",
          subtitle: "Products",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "CloudIcon",
          color: "light-success",
          title: "$9745",
          subtitle: "Revenue",
          customClass: "",
        },
        {
          icon: "RadioIcon",
          color: "light-primary",
          title: "230k",
          subtitle: "Sales",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "8.549k",
          subtitle: "Customers",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: "1.423k",
          subtitle: "Products",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "CloudIcon",
          color: "light-success",
          title: "$9745",
          subtitle: "Revenue",
          customClass: "",
        },
      ],
      types: [],
    };
  },
  mounted() {
    this.getChannelData();
    this.getDataChartSubScribe();
    this.getDataChartView();
    this.getRoles();
  },
  created() {
    this.chartjsDataVideos.lineChart.status = false;
    this.chartjsDataSubscribers.lineChart.status = false;
  },
  methods: {
    submitReport(type) {
      this.$http
        .post(`/api/city/report/request`, {
          channel_id: parseInt(this.$route.query.id),
          type_id: type,
        })
        .then((response) => {
          this.makeToast(
            this.$t("Base.Alert.success_report"),
            this.$t("Base.Alert.success_report_content"),
            "success"
          );
        })
        .catch((error) => {
          if (error.response.status == 300) {
            this.makeToast(
              this.$t("Base.Alert.has_report"),
              this.$t("Base.Alert.has_report_content"),
              "danger"
            );
          }
        });
    },
    SeeChannel() {
      window.open(
        `https://www.youtube.com/channel/${this.headerData.channelId}`,
        "_blank"
      );
    },
    getChannelData() {
      this.$http
        .get(`/api/city/channel/${this.$route.query.id}/info`)
        .then((response) => {
          const { result } = response.data;
          this.headerData = result;
          this.data_publishedAt = {
            icon: "CalendarIcon",
            color: "light-danger",
            title: result.publishedAt,
            subtitle: this.$t("Pages.City.Title.create_date"),
            customClass: "mb-2 mb-xl-0",
          };
          this.data_videos = {
            icon: "VideoIcon",
            color: "light-warning",
            title: result.videos,
            subtitle: this.$t("Pages.City.Title.Videos"),
            customClass: "mb-2 mb-xl-0",
          };
          this.data_subscribers = {
            icon: "UserIcon",
            color: "light-info",
            title: result.subscribers,
            subtitle: this.$t("Pages.City.Title.Subscriber"),
            customClass: "mb-2 mb-xl-0",
          };
          this.data_views = {
            icon: "RadioIcon",
            color: "light-primary",
            title: result.views,
            subtitle: this.$t("Pages.City.Title.View"),
            customClass: "mb-2 mb-xl-0",
          };
          this.data_sub30Days = {
            icon: "UserIcon",
            color: "light-info",
            title: result.sub30Days,
            subtitle: this.$t("Pages.City.Title.last_30_sub"),
            customClass: "mb-2 mb-xl-0",
          };
          // this.data_estimateMonthlyRevenue = {
          //   icon: "CloudIcon",
          //   color: "light-success",
          //   title: result.estimateMonthlyRevenue,
          //   subtitle: this.$t("Pages.City.Title.estimate_income"),
          //   customClass: "mb-2 mb-xl-0",
          // };
          // this.data_estimateYearlyRevenue = {
          //   icon: "CloudIcon",
          //   color: "light-success",
          //   title: result.estimateYearlyRevenue,
          //   subtitle: this.$t("Pages.City.Title.estimate_income_year"),
          //   customClass: "mb-2 mb-xl-0",
          // };
          this.data_views30Days = {
            icon: "RadioIcon",
            color: "light-primary",
            title: result.views30Days,
            subtitle: this.$t("Pages.City.Title.view_30"),
            customClass: "mb-2 mb-xl-0",
          };
        });
    },
    getDataChartSubScribe() {
      //ست کردن دیتا های مربوط به سابسکرایب
      this.$http
        .get(`/api/city/channel/${this.$route.query.id}/getDataSubscribeChart`)
        .then((response) => {
          this.chartjsDataSubscribers.lineChart.status = true;
          this.chartjsDataSubscribers.lineChart.data.labels =
            response.data.labels;
          this.chartjsDataSubscribers.lineChart.data.datasets[0].data =
            response.data.subscribe.subscribe;
          this.chartjsDataSubscribers.lineChart.data.datasets.labels =
            response.data.labels;
          this.chartjsDataSubscribers.lineChart.options.scales.yAxes[0].ticks.max =
            Math.round(response.data.subscribe.max * 1.5);
          this.chartjsDataSubscribers.lineChart.total =
            response.data.subscribe.total;
        })
        .catch((error) => {
          this.chartjsDataSubscribers.lineChart.status = true;
        });
    },
    getDataChartView() {
      //ست کردن دیتا های مربوط به ویو ها
      this.$http
        .get(`/api/city/channel/${this.$route.query.id}/getDataVideoChart`)
        .then((response) => {
          this.chartjsDataVideos.lineChart.status = true;
          this.chartjsDataVideos.lineChart.data.labels = response.data.labels;
          this.chartjsDataVideos.lineChart.data.datasets[0].data =
            response.data.view.view;
          this.chartjsDataVideos.lineChart.data.datasets.labels =
            response.data.labels;
          this.chartjsDataVideos.lineChart.options.scales.yAxes[0].ticks.max =
            Math.round(response.data.view.max * 1.5);
          this.chartjsDataVideos.lineChart.total = response.data.view.total;
        })
        .catch((error) => {
          this.chartjsDataVideos.lineChart.status = true;
        });
    },
    getRoles() {
      this.$http.get(`/api/city/report/listType`).then((response) => {
        this.types = response.data;
      });
    },
  },
  beforeDestroy() {
    this.chartjsData = null;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";

.header-card > img:first-child {
  height: 300px;
  object-fit: cover;
}
</style>
